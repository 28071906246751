<template>
  <v-container id="user-profile" fluid tag="section" class="text_google">
    <v-card justify="center" height="100%">
      <base-material-card color="primary darken-2">
        <template v-slot:heading>
          <div class="font-weight-light">
            <h2>Login ข้าราชการครูและบุคลากรทางการศึกษา</h2>
          </div>

          
          <div class="font-weight-light">Username : รหัสบัตรประชาชน</div>
          <div class="font-weight-light">
            Password : วันเดือนปีเกิด : 8/8/2531
          </div>
        </template>
        <div class="text-center">
          <lord-icon
            src="https://cdn.lordicon.com/shcfcebj.json"
            trigger="loop"
            style="width:150px;height:150px"
          >
          </lord-icon>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogLogin" persistent width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark v-bind="attrs" v-on="on" rounded>
                <v-icon size="30">mdi-login-variant</v-icon>
                เข้าสู่ระบบ
                <v-icon size="30">mdi-account-circle</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="success white--text">
                <v-icon size="30" color="white"> mdi-account-circle</v-icon>
                ข้าราชการครูและบุคลากรทางการศึกษา
              </v-card-title>

              <v-card-text class="pa-2">
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    color="yellow darken-4"
                    prepend-icon="mdi-account"
                    name="login"
                    label="ชื่อผู้ใช้งาน : รหัสบัตรประชาชน"
                    type="text"
                    v-model="id_card"
                    :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                    rounded
                    outlined
                  ></v-text-field>
                  <v-text-field
                    color="yellow darken-4"
                    name="password : 8/8/2531"
                    label="รหัสผ่าน"
                    prepend-icon="mdi-lock-outline"
                    id="password"
                    v-model="p_word"
                    rounded
                    :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    counter
                    @click:append="show1 = !show1"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  rounded
                  outlined
                  color="grey"
                  @click="dialogLogin = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn
                    rounded
                    color="success"
                    outlined
                    large
                    @click="userLogin()"
                  >
                    <v-icon>mdi-login-variant</v-icon> เข้าสู่ระบบ</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </base-material-card>
    </v-card>
    <v-snackbar v-model="dialog" top>
      <v-card-text>
        {{ dialog_msg }}
      </v-card-text>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      dialogLogin: false,
      id_card: "",
      p_word: "",
      dialog: false,
      dialog_msg: "",
      snackbar_timeout: 10000,

      personnel_etc_id_card: "",
      personnel_etc_pass: ""
    };
  },
  mounted() {
    //Null
  },
  methods: {
    async userLogin() {
      if (this.$refs.form.validate()) {
        let result = await this.$http.post("login.php?crud=user", {
          id_card: this.id_card,
          p_word: this.p_word
        });
        if (result.data.user_status) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (
            user.user_status == "teacher" ||
            user.user_status == "se_director" ||
            user.user_status == "director" ||
            user.user_status == "S" ||
            user.user_status == "38" ||
            user.user_status == "3801" ||
            user.user_status == "3802" ||
            user.user_status == "3803" ||
            user.user_status == "C"
          ) {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/user");
          } else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      }
    },

    async userEtcLogin() {
      if (this.$refs.form_etc.validate()) {
        let result = await this.$http.post("login_etc.php?crud=user", {
          personnel_etc_id_card: this.personnel_etc_id_card,
          personnel_etc_pass: this.personnel_etc_pass
        });

        if (result.data.personnel_etc_m_type) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (
            user.personnel_etc_m_type == "governmentteach" ||
            user.personnel_etc_m_type == "governmentsp" ||
            user.personnel_etc_m_type == "government" ||
            user.personnel_etc_m_type == "governmentjob"
          ) {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          } else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      }
    }
  },
  computed: {
    color() {
      return "green lighten-1";
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
</style>

<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
#btnnavi {
  width: 50%;
}
</style>
