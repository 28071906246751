<template>
  <v-container id="user-profile" fluid tag="section" class="text_google">
    <v-card justify="center">
      <base-material-card color="warning darken-1">
        <template v-slot:heading>
          <div class="font-weight-light">
            <h2>
              Login พนักงานราชการ ครูอัตราจ้าง ลูกจ้างประจำ ลูกจ้างชั่วคราว
            </h2>
          </div>

          <div class="font-weight-light">Username : รหัสบัตรประชาชน</div>
          <div class="font-weight-light">
            Password : รหัสบัตรประชาชน
          </div>
        </template>
        <div class="text-center">
          <lord-icon
            src="https://cdn.lordicon.com/knzzcfyy.json"
            trigger="loop"
            style="width:150px;height:150px"
          >
          </lord-icon>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogLogin" persistent width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="warning" dark v-bind="attrs" v-on="on" rounded>
                <v-icon size="30">mdi-login-variant</v-icon>
                เข้าสู่ระบบ
                <v-icon size="30">mdi-account-circle</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="warning white--text">
                <v-icon size="30" color="white"> mdi-account-circle</v-icon>
                พนักงานราชการ ครูอัตราจ้าง ลูกจ้างประจำ ลูกจ้างชั่วคราว
              </v-card-title>

              <v-card-text class="pa-2">
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    color="yellow darken-4"
                    prepend-icon="mdi-account"
                    name="login"
                    label="ชื่อผู้ใช้งาน : รหัสบัตรประชาชน"
                    type="text"
                    v-model="personnel_etc_id_card"
                    :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                    rounded
                    outlined
                  ></v-text-field>
                  <v-text-field
                    color="yellow darken-4"
                    name="password : 8/8/2531"
                    label="รหัสผ่าน"
                    prepend-icon="mdi-lock-outline"
                    id="password"
                    v-model="personnel_etc_pass"
                    rounded
                    :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    counter
                    @click:append="show1 = !show1"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  rounded
                  outlined
                  color="grey"
                  @click="dialogLogin = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn
                    rounded
                    color="success"
                    outlined
                    large
                    @click="userEtcLogin()"
                  >
                    <v-icon>mdi-login-variant</v-icon> เข้าสู่ระบบ</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </base-material-card>
    </v-card>
    <v-snackbar v-model="dialog" top>
      <v-card-text>
        {{ dialog_msg }}
      </v-card-text>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      dialogLogin: false,
      dialog: false,
      dialog_msg: "",
      snackbar_timeout: 10000,

      personnel_etc_id_card: "",
      personnel_etc_pass: ""
    };
  },
  mounted() {
    //Null
  },
  methods: {
    async userEtcLogin() {
      if (this.$refs.form.validate()) {
        let result = await this.$http.post("login_etc.php?crud=user", {
          personnel_etc_id_card: this.personnel_etc_id_card,
          personnel_etc_pass: this.personnel_etc_pass
        });

        if (result.data.personnel_etc_m_type) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (
            user.personnel_etc_m_type == "governmentteach" ||
            user.personnel_etc_m_type == "governmentsp" ||
            user.personnel_etc_m_type == "government" ||
            user.personnel_etc_m_type == "temporary" ||
            user.personnel_etc_m_type == "temporary_job" ||       
            user.personnel_etc_m_type == "permanent"
            
          ) {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/userEtc");
          } else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        } else {
          sessionStorage.clear();
          this.dialog = true;
          this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
        }
      }
    }
  },
  computed: {
    color() {
      return "green lighten-1";
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
</style>

<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
#btnnavi {
  width: 50%;
}
</style>
