<template>
  <v-container id="user-profile" fluid tag="section" class="text_google">
    <v-card justify="center" height="100%">
      <base-material-card color="error darken-2">
        <template v-slot:heading>
          <div class="font-weight-light">
            <h2>Login วิทยาลัย  สอจ. สถาบัน กจ.2 ผู้ดูแลระบบ</h2>
          </div>

          <div class="font-weight-light">Username : รหัสสถานศึกษา</div>
          <div class="font-weight-light">
            Password : ตามที่ส่วนกลางกำหนด
          </div>
        </template>
        <div class="text-center">
          <lord-icon
            src="https://cdn.lordicon.com/vvyxyrur.json"
            trigger="loop"
              state="loop-cycle"
            style="width:150px;height:150px"
          >
          </lord-icon>
        </div>
        <div class="text-center">
          <v-dialog v-model="dialogLogin" persistent width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error darken-2" dark v-bind="attrs" v-on="on" rounded>
                <v-icon size="30">mdi-login-variant</v-icon>
                เข้าสู่ระบบ
                <v-icon size="30">mdi-account-circle</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="error darken-2 white--text">
                <v-icon size="30" color="white"> mdi-account-circle</v-icon>
                วิทยาลัย  สอจ. สถาบัน กจ.2 ผู้ดูแลระบบ
              </v-card-title>

              <v-card-text class="pa-2">
                <v-form ref="form" lazy-validation>
                  <v-text-field
                    color="yellow darken-4"
                    prepend-icon="mdi-account"
                    name="login"
                    label="ชื่อผู้ใช้งาน"
                    type="text"
                    v-model="user_name"
                    :rules="[v => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                    rounded
                    outlined
                  ></v-text-field>
                  <v-text-field
                    color="yellow darken-4"
                    name="password"
                    label="รหัสผ่าน"
                    prepend-icon="mdi-lock-outline"
                    id="password"
                    v-model="user_password"
                    rounded
                    :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    counter
                    @click:append="show1 = !show1"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  rounded
                  outlined
                  color="grey"
                  @click="dialogLogin = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn
                    rounded
                    color="success"
                    outlined
                    large
                    @click="userLogin()"
                  >
                    <v-icon>mdi-login-variant</v-icon> เข้าสู่ระบบ</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </base-material-card>
    </v-card>
    <v-snackbar v-model="dialog" top>
      <v-card-text>
        {{ dialog_msg }}
      </v-card-text>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dialog = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ApiKey: 'HRvec2021',
      show1: false,
      dialogLogin: false,
      data_syslog:{},

      user_name: "",
      user_password: "",
      dialog: false,
      dialog_msg: "",
      snackbar_timeout: 10000,
    };
  },
  mounted() {
    //Null
  },
  methods: {
    async userLogin() {
      if (this.$refs.form.validate()) {
        let result = await this.$http.post("login.php?crud=admin", {
          user_name: this.user_name,
          user_password: this.user_password
        });
        
        if (result.data.user_status) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (user.user_status == "A") {
          
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = this.user_name;
          this.data_syslog.event_log = "login-admin";
          this.data_syslog.page_log = "loginAdmin";
          this.data_syslog.table_log = "";
          this.data_syslog.date_times = this.date_today_log;          

          await this.$http.post('data_syslog.insert.php', this.data_syslog)

            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/admin");
          }       
          else if (user.user_status == "B") {
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/college");
          } 
          else if (user.user_status == "C"){
           this.$router.push("/iven"); 
          }   
          else if (user.user_status == "D") 
          {
            this.$router.push("/personnel");
          }                   
        
          else if (user.user_status == "G"){
            sessionStorage.setItem("user", JSON.stringify(user));           
            this.$router.push("/vecprovince");
          } 
          else if (user.user_status == "S"){
            sessionStorage.setItem("user", JSON.stringify(user));           
            this.$router.push("/supervisions");
          } 
          //Vocation College Province
          else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      }    
    },   
  },
  computed: {
    color() {
      return "green lighten-1";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
</style>

<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
#btnnavi {
  width: 50%;
}
</style>
