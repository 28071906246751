<template>
  <v-container id="user-profile" fluid tag="section" class="text_google">
    <v-row no-gutters class="mt-10" align="stretch">
      <v-col cols="12" md="9" class="flex-card">
        <v-row no-gutters align="stretch">
          <v-col cols="12" md="6"> <Loginuser /></v-col>
          <v-col cols="12" md="6"> <LoginCollege /></v-col>
          <v-col cols="12" md="6"> <LoginuserEtc /></v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3" class="flex-card">
        <base-material-card class="v-card-profile" avatar="ovec.png">
          <v-card-text class="text-center" height="400px">
            <h2 class="font-weight-light mb-1 grey--text">เข้าสู่ระบบ</h2>
            <h1 class="font-weight-light mb-3 black--text">
              ข้าราชการครูและบุคลากรทางการศึกษา
            </h1>
            <p class="font-weight-light grey--text">
              ชื่อผู้ใช้ระบบ : รหัสบัตรประชาชน
            </p>
            <p class="font-weight-light red--text">
              รหัสผ่าน : วันเดือนปีเกิด "8/8/2531" ไม่ต้องระบุ 0 นำหน้า
            </p>
            <p class="font-weight-light black--text">
              เข้าสู่ระบบแล้วให้ดำเนินการเปลี่ยนรหัสผ่านของท่าน
            </p>
            <v-btn color="success" to="/news" rounded class="mr-0"
              >คู่มือ</v-btn
            >
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>   
  </v-container>
</template>

<script>
import LoginCollege from "../components/core/loginCollege.vue";
import Loginuser from "../components/core/loginuser.vue";
import LoginuserEtc from "../components/core/loginuserEtc.vue";

export default {
  components: {
    Loginuser,
    LoginCollege,
    LoginuserEtc
  },
  data() {
    return {};
  },
  mounted() {
    //Null
  },
  methods: {},
  computed: {
    color() {
      return "green lighten-1";
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
</style>

<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
#btnnavi {
  width: 50%;
}
</style>
